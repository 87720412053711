import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types'


const StyledCaseStudyHero = styled(props => <Img {...props} />)`
    width: 100%;
    height: 90vh;
    background: #333;
`;

const BlankHeroPlaceholder = styled.div`
    width: 100%;
    height: 168px;
`;

class CaseStudyHero extends Component {

    render() {
        return (
            this.props.heroFluid[0] ? 
            <StyledCaseStudyHero 
            fluid={this.props.heroFluid[0].node.childImageSharp.fluid}
            objectFit='cover'
            ></StyledCaseStudyHero> 
            : <BlankHeroPlaceholder></BlankHeroPlaceholder>   
        )
    }
}

export default CaseStudyHero

CaseStudyHero.propTypes = {
    heroFluid: PropTypes.array
}