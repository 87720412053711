import React, { Component, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import styled from "styled-components"

import { device, spacers, gridGutter, RGtheme } from "./utils/designsystem"
import SectionHeader from "../components/SectionHeader"

const ComponentViewStack = styled.div`
  display: flex;
  flex-direction: column;

  grid-gap: ${spacers["56"].px};
  justify-content: flex-start;

  margin-top: ${spacers["56"].px};
  margin-bottom: ${spacers["56"].px};
`

const StyledRow = styled(motion.div)`
  width: 100%;

  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
    grid-gap: ${gridGutter.mobileS.rem};
  }
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 240px 1fr 1fr;
    grid-gap: ${gridGutter.tablet.rem};
  }
`

const StyledRowHeader = styled(motion.p)`
  font-size: ${RGtheme.typeStyles.dBody.fontsize};
  line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  width: ${spacers["144"].px};
  margin-right: ${spacers["16"].px};
`

const StyledParagraph = styled(motion.p)`
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

  flex-grow: 2;
`

const StyledParagraphTwo = styled(motion.p)`
  font-size: ${RGtheme.typeStyles.mBody.fontsize};
  line-height: ${RGtheme.typeStyles.mBody.lineHeight};

  @media ${device.laptop} {
    font-size: ${RGtheme.typeStyles.dBody.fontsize};
    line-height: ${RGtheme.typeStyles.dBody.lineHeight};
  }

  flex-grow: 2;
`
const RowVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.6,
      delayChildren: 0.4,
      ease: "easeIn",
      duration: 1,
    }
  },
  hidden: {
    opacity: 0,
  },
}

function setParagraphTwo(htmlText) {
  let setData = htmlText !== "none" ? htmlText : ""

  return { __html: setData }
}

function CaseStudyHeader (props) {

  const [ref, inView] = useInView({
    threshold: 0.8,
    delay: 4,
  })

  return(
      <ComponentViewStack>
        <SectionHeader hasBottomPadding={false}>
          {props.data.display.title}
        </SectionHeader>

        <StyledRow
          ref={ref}
          layout
          initial={"hidden"}
          animate={"visible"}
          variants={RowVariants}
        >
          {props.data.caseStudy.overviewTextPar1 !== 'none' ?<StyledRowHeader
            variants={RowVariants}
          >Overview</StyledRowHeader> : ''}
          { props.data.caseStudy.overviewTextPar1!== 'none' ? 
          <StyledParagraph
          dangerouslySetInnerHTML={{
            __html: props.data.caseStudy.overviewTextPar1,
          }}
          variants={RowVariants}
        ></StyledParagraph> : '' }
          <StyledParagraphTwo
            variants={RowVariants}
            dangerouslySetInnerHTML={setParagraphTwo(
              props.data.caseStudy.overviewTextPar2
            )}
          ></StyledParagraphTwo>
        </StyledRow>

        <StyledRow
          ref={ref}
          initial={"hidden"}
          animate={"visible"}
          variants={RowVariants}
        >
          <StyledRowHeader variants={RowVariants}>Role
          </StyledRowHeader>
          <StyledParagraph variants={RowVariants}>
            {props.data.caseStudy.roleText}
          </StyledParagraph>
        </StyledRow>
      </ComponentViewStack>
    )
  }


export default CaseStudyHeader
