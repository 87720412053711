import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"

import AppLayoutTemplate from "../frames/MainLayout"
import CaseStudyHero from "../components/CaseStudyHero"
import CaseStudyHeader from "../components/CaseStudyHeader"
import { ApplyLayoutFrame as SnapToPageGrid } from "../frames/ResponsiveModuleContainer"
import CaseStudySlides from "../components/CaseStudySlides"
import ResponsiveSpacer from "../components/ResponsiveSpacer"
import { device, RGtheme } from "../components/utils/designsystem"

import "../styles/hover-animation.css"

const NavControlsFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
`

const AlignRight = styled.div`
  text-align: right;
`

const LinkWrapper = styled.div`
  height: 64px;
  font-size: ${RGtheme.typeStyles.mHeader.fontsize};
  line-height: ${RGtheme.typeStyles.mHeader.lineHeight};
 


  &:hover {
  }

  &:last-of-type {
    text-align: right;
    margin-right: 0px;
  }
`

const ApplyTopMargin = styled.div`
  padding-top: 96px;

  @media ${device.laptop} {
    padding-top: 168px;
  }
`

const CaseStudy = ({ pageContext, data }) => (
  <AppLayoutTemplate>
    <ApplyTopMargin>
      <CaseStudyHero
        heroFluid={data.heroImageQuery.edges.filter(item =>
          item.node.relativeDirectory.startsWith(pageContext.csSlug)
        )}
      />
    </ApplyTopMargin>

    <SnapToPageGrid>
      <CaseStudyHeader data={pageContext.csProjectData} />
    </SnapToPageGrid>

    <CaseStudySlides
      slug={pageContext.csSlug}
      title={"Case Study Slide"}
      catalog={pageContext.slidesCatalog}
      imgSharps={data.slideAssetsQuery.edges}
    />
  <ResponsiveSpacer> </ResponsiveSpacer>
    <SnapToPageGrid>
      <div className="rg-casestudy-navbar">
        <NavControlsFrame>
          {pageContext.csShowPrevLink ? (
            <LinkWrapper className="hvr-bob">
              <Link to={pageContext.csPrevRoute}>–Previous Project</Link>
            </LinkWrapper>
          ) : (
            <p></p>
          )}

          {pageContext.csShowNextLink ? (
            <LinkWrapper className="hvr-bob">
              <Link to={pageContext.csNextRoute}>
                <AlignRight>Next Project–</AlignRight>
              </Link>
            </LinkWrapper>
          ) : (
            <p></p>
          )}
        </NavControlsFrame>
      </div>
    </SnapToPageGrid>
    <ResponsiveSpacer> </ResponsiveSpacer>
  </AppLayoutTemplate>
)

export default CaseStudy

export const data = graphql`
  query {
    heroImageQuery: allFile(
      filter: {
        sourceInstanceName: { eq: "case-studies" }
        relativeDirectory: { regex: "/hero/" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          sourceInstanceName
          base
          childImageSharp {
            id
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    slideAssetsQuery: allFile(
      filter: { sourceInstanceName: { eq: "case-studies" } }
    ) {
      edges {
        node {
          relativeDirectory
          sourceInstanceName
          base
          childImageSharp {
            id
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
