import React, { Component } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types'

/* props - slug, catalog, imgSharps 
collects slide info and sharp object into array and outputs all slides set to 'visible'
*/

export default class CaseStudySlides extends Component {
    render() {

    const slidesArray = [];

    this.props.catalog.forEach(slideItem => { 
      let targetFilename = slideItem.filename;
      let holderObj = {};
      holderObj.order = slideItem.order;
      holderObj.visible = slideItem.isVisible;
      holderObj.imgSharp = this.props.imgSharps.filter(sharpItem => (sharpItem.node.base === targetFilename));
      slidesArray.push(holderObj);
    });

    return (
        slidesArray.map((slide) =>
        ( 
        (slide.visible) ? <Img 
        fluid = { slide.imgSharp[0].node.childImageSharp.fluid }
        id = { slide.order }
        key = { slide.order }
        alt = { this.props.csTitle } 
        /> : ''
        ))
      )
    }
}

CaseStudySlides.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  catalog: PropTypes.array,
  imgSharps: PropTypes.array,
}